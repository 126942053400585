import { useContext } from 'react';
import team1 from '../assests/logo royal.png';
import team2 from '../assests/logo skis.png';
import team3 from '../assests/logo HUTECH.png';
import team4 from '../assests/logo CIS.png';
import team5 from '../assests/VAS.png';
import team6 from '../assests/kr_flag.png';
import MicromouseRacing from "../components/MicromouseRacing";
import SUMO from "../components/SUMO";
import { AppContext, AppContextProps } from '../App';

export const img = [team1, team2, team3, team4,team5, team6];



const HomePage = () => {
    const {pageContent} = useContext(AppContext) as AppContextProps;

    return <div className="mt-5 mb-20">
        <SUMO category={"GROUP_SUMO_REGULAR_A"} title={`${pageContent.home.sumoRegularA}`}/>
        <SUMO category={"GROUP_SUMO_REGULAR_B"}  title={`${pageContent.home.sumoRegularB}`}/>
        <SUMO category={"GROUP_SUMO_REGULAR_C"}  title={`${pageContent.home.sumoRegularC}`}/>
        <SUMO category={"GROUP_SUMO_REGULAR_D"}  title={`${pageContent.home.sumoRegularD}`}/>
        <SUMO category={"SUMO_REGULAR_quarter"}  title={`${pageContent.home.sumoRegQuarterTitle}`}/>
        <SUMO category={"SUMO_REGULAR_semi"}  title={`${pageContent.home.sumoRegSemiTitle}`}/>
        <SUMO category={"SUMO_REGULAR_final"}  title={`${pageContent.home.sumoRegFinalTitle}`}/>
        <SUMO category={"SUMO_REGULAR_third"}  title={`${pageContent.home.sumoRegThirdTitle}`}/>
        <MicromouseRacing round={1} category={"RACING"} title={`${pageContent.home.round1Title}`}/>
        <MicromouseRacing round={2} category={"RACING"} title={`${pageContent.home.round2Title}`}/>
        {/*<MicromouseRacing round={1} category={"RACING"}/>*/}
        {/*<MicromouseRacing round={2} category={"RACING"}/>*/}
    </div>
}

export default HomePage;
