import React, {useContext} from "react";
import logo2 from '../assests/1_White.png'
import {Link} from "react-router-dom";
import {Authenticator} from "@aws-amplify/ui-react";
import { AppContext, AppContextProps } from "../App";
import enFlag from '../assests/en.jpeg';
import krFlag from '../assests/kr_flag.png'

const NavBar = () => {
    const {pageContent, setLanguage, appLanguage} = useContext(AppContext) as AppContextProps;

    return <div className="sticky top-0 z-40 flex justify-between items-center bg-[#222222] py-4 px-3">
        <div className="w-44 " >
            <img src={logo2} alt={"Logo"} className=""/>
        </div>
        <div className="mr-32 flex flex-row gap-4 items-center text-xl text-blue-400">
            <div onClick={() => {
                if (appLanguage === 'en') {
                    setLanguage('kr');
                }else {
                    setLanguage('en');
                }
            }}>
                <img src={!(appLanguage === 'en') ? enFlag : krFlag} className="w-16 h-auto"/>
            </div>
            <div >
                <Link to={`/`}>{pageContent.home.title}</Link>
            </div>
            <div className={`relative w-fit`}>
                <Link to={`/group-stage`} >{pageContent.group.title}</Link>
            </div>
            <div>
               <Link to={"/teams"} >{pageContent.team.title}</Link>
            </div>
            <Authenticator>
                {({ signOut, user }) => (
                    <div onMouseEnter={() => {
                    }}>
                        <button onClick={signOut} >Sign Out</button>
                    </div>
                )}
            </Authenticator>

        </div>
    </div>
}

export default NavBar;
