type HomeProps = {
    title: string;
    sumoRegularA: string;
    seumoUniA: string;
    sumoRegularB: string;
    seumoUniB: string;
    sumoRegularC: string;
    seumoUniC: string;
    sumoRegularD: string;
    seumoUniD: string;
    round1Title: string;
    round2Title: string;
    sumoRegQuarterTitle: string;
    sumoRegSemiTitle: string;
    sumoUniQuarterFinal: string;
    sumoUniSemiFinal: string;
    sumoRegFinalTitle: string;
    sumoRegThirdTitle: string;
    sumoUniFinalTitle: string;
    sumoUniThirdTitle: string;
    view: string;
    start: string;
    end: string;
    cancel: string;
    confirmScoreMsg: string;
    confirm: string;
    save: string;
}

type GroupProps = {
    title: string;
    boardTitle: string;
    team: string;
    win: string;
    draw: string;
    lose: string;
    points: string;
    createBoard: string;
    createMatch: string;
}

type TeamAttend = { 
    title: string;
    teamName: string;
    teamOrg: string;
    categoryTitle: string;
    categorie: string[];
    addTeam: string;
    loadTeam: string;
}

export type Content = {
    home: HomeProps;
    group: GroupProps;
    team: TeamAttend;
}

export type LanguageProps = {
    en: Content;
    kr: Content;
}

export const language: LanguageProps = {
    en: {
        home: {
            title: 'Home',
            sumoRegularA: 'SUMO REGULAR GROUP A',
            sumoRegularB: 'SUMO REGULAR GROUP B',
            sumoRegularC: 'SUMO REGULAR GROUP C',
            sumoRegularD: 'SUMO REGULAR GROUP D',
            seumoUniA: 'SUMO UNIVERSITY GROUP A',
            seumoUniB: 'SUMO UNIVERSITY GROUP B',
            seumoUniC: 'SUMO UNIVERSITY GROUP C',
            seumoUniD: 'SUMO UNIVERSITY GROUP D',
            round1Title: 'RACING ROUND 1',
            round2Title: 'RACING ROUND 2',
            sumoRegQuarterTitle: 'SUMO REGULAR QUARTER FINAL',
            sumoRegSemiTitle: 'SUMO REGULAR SEMI FINAL',
            sumoUniQuarterFinal: 'SUMO UNIVERSITY QUARTER FINAL',
            sumoUniSemiFinal: 'SUMO UNIVERSITY SEMI FINAL',
            sumoRegFinalTitle: 'SUMO REGULAR FINAL',
            sumoUniFinalTitle: 'SUMO UNIVERSITY FINAL',
            sumoRegThirdTitle: 'SUMO REGULAR THIRD PLACE',
            sumoUniThirdTitle: 'SUMO UNIVERSITY THIRD PLACE',
            view: 'View',
            start: 'Start',
            end: 'End Match',
            cancel: 'Cancel',
            confirmScoreMsg: 'Please Confirm Result',
            confirm: 'Confirm',
            save: 'Save'
        },
        group: {
            title: 'Group Stage',
            boardTitle: 'Group',
            team: 'Team',
            win: 'Win',
            draw: 'Draw',
            lose: 'Lose',
            points: 'Points',
            createBoard: 'Create Board',
            createMatch: 'Create Match'
        },
        team: {
            title: 'Teams',
            teamName: 'Team Name',
            teamOrg: 'Team Organizer',
            categoryTitle: 'Category',
            categorie: [
                'Drone University',
                'Drone Regular',
                'Sumo University',
                'Sumo Regular',
                'Racing'
            ],
            addTeam: 'Add Team',
            loadTeam: 'Load Team'
        }
    },
    kr: {
        home: {
            title: '홈',
            sumoRegularA: '스모 정규 그룹 A',
            sumoRegularB: '스모 정규 그룹 B',
            sumoRegularC: '스모 정규 그룹 C',
            sumoRegularD: '스모 정규 그룹 D',
            seumoUniA: '스모 대학교 그룹 A',
            seumoUniB: '스모 대학교 그룹 B',
            seumoUniC: '스모 대학교 그룹 C',
            seumoUniD: '스모 대학교 그룹 D',
            round1Title: '경기 라운드 1',
            round2Title: '경기 라운드 2',
            sumoRegQuarterTitle: '스모 정규 8강',
            sumoRegSemiTitle: '스모 정규 준결승',
            sumoUniQuarterFinal: '스모 대학교 8강',
            sumoUniSemiFinal: '스모 대학교 준결승',
            sumoRegFinalTitle: '스모 정규 결승',
            sumoUniFinalTitle: '스모 대학교 결승',
            sumoRegThirdTitle: '스모 정규 3위 결정전',
            sumoUniThirdTitle: '스모 대학교 3위 결정전',
            view: '보기',
            start: '시작',
            end: '경기 종료',
            cancel: '취소',
            confirmScoreMsg: '결과를 확인해 주세요',
            confirm: '확인',
            save: '저장'
        },
        group: {
            title: '조별 리그',
            boardTitle: '그룹',
            team: '팀',
            win: '승리',
            draw: '무승부',
            lose: '패배',
            points: '점수',
            createBoard: '보드 생성',
            createMatch: '경기 생성'
        },
        team: {
            title: '팀',
            teamName: '팀 이름',
            teamOrg: '팀 조직자',
            categoryTitle: '카테고리',
            categorie: [
                '드론 대학교',
                '드론 정규',
                '스모 대학교',
                '스모 정규',
                '레이싱'
            ],
            addTeam: '팀 추가',
            loadTeam: '팀 불러오기'
        }
    }
}