import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import NavBar from "./components/NavBar";
import RefereeBoard from "./pages/RefereeBoard";
import Teams from "./pages/Teams";
import GroupStage from "./pages/GroupStage";
import {language, Content } from './utils/language';

export interface AppContextProps {
    appLanguage: string;
    setLanguage: Dispatch<SetStateAction<string>>;
    pageContent: Content;
}

export const AppContext = React.createContext<AppContextProps | null>(null);

function App() {
    const [appLanguage, setLanguage] = useState('');
    const [pageContent, setPageContent] = useState<Content>(language.en);

    useEffect(() => {
        const storedLanguage = localStorage.getItem('meta_square_scoring_sys');
        if (storedLanguage) {
            setLanguage(storedLanguage);
            console.log(`LAN: ${storedLanguage}`);
        }
    }, [])

    useEffect(() => {
        if (appLanguage.length === 0) {
            return;
        }
        
        if (appLanguage === 'en') {
            console.log('set here en');
            setPageContent(language.en);
            localStorage.meta_square_scoring_sys = 'en';
        }else {
            console.log('set here');
            
            setPageContent(language.kr);
            localStorage.meta_square_scoring_sys = 'kr';
        }
    }, [appLanguage])

    return (
        <AppContext.Provider value={{appLanguage, setLanguage, pageContent}}>
            <div className="relative" >
                <NavBar/>
                <Routes>
                    <Route path={"/"} element={<HomePage/>} />
                    <Route path={"/score-board"} element={<RefereeBoard/>}/>
                    <Route path={"/teams"} element={<Teams/>}/>
                    <Route path={"/group-stage"} element={<GroupStage/>}/>
                </Routes>
            </div>
        </AppContext.Provider>
    );
}

export default App;
