import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {Authenticator} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import awsmobile from "./AppSync";
import {Amplify} from "aws-amplify";

const appConfig = awsmobile

Amplify.configure({
    Auth: {
        region: 'ap-northeast-2',
        userPoolId: 'ap-northeast-2_XovP2kpdC',
        identityPoolId: 'ap-northeast-2:63b7fbc6-33dc-438d-b356-32efd8510da3',
        userPoolWebClientId: 'pq3tqufatsaqn1h32k0hdau95',
        mandatorySignIn: false
    }
})

Amplify.configure(appConfig);


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <React.StrictMode>
            <Authenticator hideSignUp>
                <App/>
            </Authenticator>
        </React.StrictMode>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
